import React, {
    useRef,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "component/Button/Button";
import Carousel from "../../component/Carousel/Carousel";
import Collapse from "component/Collapse/Collapse";
import PostVideoCarousel from "Widgets/Posts/PostVideoCarousel";
import ChallengesWidget from "Widgets/Challenges";
import HuntsWidget from "Widgets/Hunts";
import ClubWidget from "Widgets/Club";
import SuggestedUsersWidget from "Widgets/SuggestedUsers";
import ThemeLayout from "layout/themeLayout";
import { challenges as challengesApi } from "api/challenges";
import { posts as postsApi } from "api/posts";
import { news_feed as newsFeedApi } from "../../api/news_feed";
import { clubs as clubsApi } from "api/clubs";
import { hunts as huntsApi } from "api/hunts";
import { users as usersApi } from "api/users";
import { interests as interestsApi } from "../../api/interests";
import { useDispatch } from "react-redux";
import styles from "./home.module.css";
import InterestCarousel from "../../Widgets/InterestCarousel";
import NewsFeedItem from "../../Widgets/Posts/NewsFeedItem";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../component/Spinner/Spinner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickCarousel from "../../component/SlickCarousel/SlickCarousel";
import { useSelector } from "react-redux";
import { StaticRouter } from "react-router-dom/cjs/react-router-dom.min";
import MinimalCarousel from "../../component/MinimalCarousel/MinimalCarousel";

const Home = React.memo(() => {
    const [challenges, setChallenges] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [users, setUsers] = useState([]);
    const [hunts, setHunts] = useState([]);
    const [posts, setPosts] = useState([]);
    const [carouselPosts, setCarouselPosts] = useState([]);
    const [interests, setInterests] = useState([]);
    const [selectedInterest, setSelectedInterest] = useState(null);
    const postPagination = useRef({});
    const currentPage = useRef(0);
    const auth_user = useSelector((store) => store.user.user);
    const dispatch = useDispatch();
    const reportedPost = useSelector((store) => store.report?.post);

    useEffect(() => {
        if (reportedPost) {
            setPosts(
                posts.filter(
                    (p) => !(p.type == "user" && p.item.id == reportedPost)
                )
            );
        }
    }, [reportedPost]);

    // load challenges
    useEffect(() => {
        dispatch(
            challengesApi({
                param_path: "suggested",
            })
        )
            .then((response) => {
                setChallenges(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    //load interests
    useEffect(() => {
        dispatch(interestsApi())
            .then((response) => {
                setInterests(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    // load clubs
    useEffect(() => {
        dispatch(
            clubsApi({
                param_path: "suggested",
            })
        )
            .then((response) => {
                setClubs(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    // load users
    useEffect(() => {
        dispatch(
            usersApi({
                params: {
                    per_page: 5,
                    page_no: 1,
                },
                param_path: "suggested",
            })
        )
            .then((response) => {
                setUsers(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    const getCarouselPosts = useCallback(async () => {
        try {
            const response = await dispatch(postsApi());
            if (response.success) {
                setCarouselPosts(response.data);
            }
        } catch (e) {
            console.log(e);
        }
    }, [dispatch]);

    const getUserPosts = useCallback(async () => {
        try {
            const params = {
                isBackground: currentPage.current == 0,
                params: {
                    page_no: currentPage.current + 1,
                    interest_id: selectedInterest,
                },
            };
            const response = await dispatch(newsFeedApi(params));
            postPagination.current = response.pagination_data;
            currentPage.current = postPagination.current?.current_page;

            if (response.success) {
                setPosts((p) =>
                    currentPage.current > 1
                        ? p.concat(response.data)
                        : response.data
                );
            }
        } catch (e) {
            console.log(e);
        }
    }, [dispatch, selectedInterest]);

    // load posts
    useEffect(() => {
        getUserPosts();
    }, [getUserPosts, selectedInterest]);

    // load carousel posts
    useEffect(() => {
        getCarouselPosts();
    }, [getCarouselPosts]);

    // load hunts
    useEffect(() => {
        dispatch(
            huntsApi({
                param_path: "suggested",
            })
        )
            .then((response) => {
                setHunts(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dispatch]);

    const selectInterest = (id) => {
        currentPage.current = 0;
        if (selectedInterest == id) setSelectedInterest(null);
        else setSelectedInterest(id);
    };

    const renderInterests = useMemo(() => {
        return interests?.map((i) => (
            <InterestCarousel
                interest={i}
                key={i.id}
                onClick={() => selectInterest(i.id)}
                isActive={i.id == selectedInterest}
            />
        ));
    }, [interests, selectedInterest]);

    const renderClubs = useMemo(() => {
        return clubs?.map((c) => (
            <div key={c.id}>
                <div className="mx-2">
                    <ClubWidget club={c} />
                </div>
            </div>
        ));
    }, [clubs]);

    const history = useHistory();
    const taskCollapseRef = useRef();
    const taskMblCollapseRef = useRef();

    const updatePost = (post) => {
        setPosts((prev) => {
            return prev.map((p) => {
                if (
                    (p.type == "user" || p.type == "club") &&
                    post.id == p.item.id
                ) {
                    p.item = post;
                }

                return p;
            });
        });
    };

    const deletePost = (id) => {
        setPosts((prev) => {
            return prev.filter((p) => p.item.id !== id);
        });
    };

    const renderPosts = useMemo(() => {
        const renderPostItem = () =>
            posts.map((p, i) => (
                <NewsFeedItem
                    key={i}
                    item={p.item}
                    type={p.type}
                    updatePost={updatePost}
                    deletePost={deletePost}
                />
            ));

        const onEndReach = () => {
            getUserPosts();
        };

        return (
            <InfiniteScroll
                pageStart={0}
                loadMore={onEndReach}
                hasMore={
                    postPagination.current?.current_page <
                    postPagination.current?.last_page
                }
                loader={<Spinner key="Loadmore" type="LoadMore" />}
                threshold={50}
                useWindow={true}
            >
                {renderPostItem()}
            </InfiniteScroll>
        );
    }, [getUserPosts, posts]);

    return (
        <div>
            <ThemeLayout>
                <nav className="sidebar d-block d-lg-none">
                    <ul className="menu-slide">
                        <Link to={"/"}>
                            <li style={{ cursor: "pointer" }}>
                                <i className="">
                                    <svg
                                        className="feather feather-zap"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        height={24}
                                        width={24}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                                    </svg>
                                </i>
                                On Reverie
                            </li>
                        </Link>
                        <li style={{ cursor: "pointer" }}>
                            <a href="chl-5.html">
                                <i className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-award"
                                    >
                                        <circle cx={12} cy={8} r={7} />
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
                                    </svg>
                                </i>
                                Challenges
                            </a>
                        </li>
                        <li>
                            <Link to="/hunting">
                                <i className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-briefcase"
                                    >
                                        <rect
                                            x={2}
                                            y={7}
                                            width={20}
                                            height={14}
                                            rx={2}
                                            ry={2}
                                        />
                                        <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
                                    </svg>
                                </i>
                                Hunt
                            </Link>
                        </li>
                        <li>
                            <a href="">
                                <i className="">
                                    <svg
                                        className="feather feather-users"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                        strokeWidth={2}
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        height={24}
                                        width={24}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                        <circle r={4} cy={7} cx={9} />
                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                    </svg>
                                </i>
                                Club
                            </a>
                        </li>
                        <li>
                            <Button
                                htmlType="link"
                                onClick={() => history.push("/create-post")}
                            >
                                <i className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-video"
                                    >
                                        <polygon points="23 7 16 12 23 17 23 7" />
                                        <rect
                                            x={1}
                                            y={5}
                                            width={15}
                                            height={14}
                                            rx={2}
                                            ry={2}
                                        />
                                    </svg>
                                </i>
                                Upload Video
                            </Button>
                        </li>
                        <li>
                            <Button
                                htmlType="link"
                                onClick={() =>
                                    history.push("/create-challenge")
                                }
                            >
                                <i className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-award"
                                    >
                                        <circle cx={12} cy={8} r={7} />
                                        <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
                                    </svg>
                                </i>
                                Create Challenge
                            </Button>
                        </li>
                        <li>
                            <a href="">
                                <i className="">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-users"
                                    >
                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                        <circle cx={9} cy={7} r={4} />
                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                    </svg>
                                </i>
                                Create Club
                            </a>
                        </li>
                    </ul>
                </nav>
                <section></section>
                {/* nav sidebar */}
                <section>
                    <div className="col-lg-12">
                        <div className="row" id="page-contents">
                            <div className="col-md-12 d-lg-none d-md-block d-sm-block">
                                <ul
                                    className={`${styles["ak-groups"]} ak-groups ${styles["ak-groups-top"]} ak-groups-top`}
                                >
                                    <li
                                        className={`${styles["active-menu"]} mb-0 p-1`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/")}
                                    >
                                        <figure>
                                            {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-layers-fill" viewBox="0 0 16 16">
                                                        <path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z" />
                                                        <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l-5.17-2.756z" />
                                                    </svg>
                                                </i> */}
                                            <i className="icofont-layers text-info mr-2"></i>
                                        </figure>
                                        <div
                                            className={`${styles["your-grp"]} your-grp`}
                                        >
                                            <h5>On Reverie</h5>
                                        </div>
                                    </li>
                                    <li
                                        className="mb-0 p-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            history.push("/challenge")
                                        }
                                    >
                                        <figure>
                                            {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-trophy-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                                                    </svg>
                                                </i> */}
                                            <i className="icofont-trophy text-warning mr-2"></i>
                                        </figure>
                                        <div
                                            className={`${styles["your-grp"]} your-grp`}
                                        >
                                            <h5>Challenges</h5>
                                        </div>
                                    </li>
                                    <li
                                        className="mb-0 p-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/hunting")}
                                    >
                                        <figure>
                                            {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                                                    </svg>
                                                </i> */}
                                            <i className="icofont-briefcase text-danger mr-2"></i>
                                        </figure>
                                        <div
                                            className={`${styles["your-grp"]} your-grp`}
                                        >
                                            <h5>Hunts</h5>
                                        </div>
                                    </li>
                                    <li
                                        className="mb-0 p-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push("/clubs")}
                                    >
                                        <figure>
                                            {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                        <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                                    </svg>
                                                </i> */}
                                            <i className="icofont-users-social text-success mr-2"></i>
                                        </figure>
                                        <div
                                            className={`${styles["your-grp"]} your-grp`}
                                        >
                                            <h5>Clubs</h5>
                                        </div>
                                    </li>
                                    <li
                                        className="create-btn mb-0 p-1"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            taskMblCollapseRef.current?.collapse(
                                                (e) => !e
                                            )
                                        }
                                    >
                                        <figure>
                                            {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24">
                                                        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                                                    </svg>
                                                </i> */}
                                            <i className="icofont-plus text-info mr-2"></i>
                                        </figure>
                                        <div
                                            className={`${styles["your-grp"]} your-grp`}
                                        >
                                            <h5>Create</h5>
                                        </div>
                                    </li>
                                    <Collapse ref={taskMblCollapseRef}>
                                        <>
                                            <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                history.push(
                                                                    "/create-post"
                                                                )
                                                            }>
                                                <figure>
                                                    {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-save2-fill" viewBox="0 0 16 16">
                                                                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v6h-2a.5.5 0 0 0-.354.854l2.5 2.5a.5.5 0 0 0 .708 0l2.5-2.5A.5.5 0 0 0 10.5 7.5h-2v-6z" />
                                                            </svg>
                                                        </i> */}
                                                    <i className="icofont-video text-danger mr-2"></i>
                                                </figure>
                                                <div
                                                    className={`${styles["your-grp"]} your-grp`}
                                                >
                                                    <h5>
                                                        {/* <Button
                                                            htmlType="link"
                                                            onClick={() =>
                                                                history.push(
                                                                    "/create-post"
                                                                )
                                                            }
                                                        > */}
                                                            Upload Video
                                                        {/* </Button> */}
                                                    </h5>
                                                </div>
                                            </li>
                                            <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                history.push(
                                                                    "/create-challenge"
                                                                )
                                                            }>
                                                <figure>
                                                    <i className="icofont-trophy text-warning mr-2"></i>
                                                </figure>
                                                <div
                                                    className={`${styles["your-grp"]} your-grp`}
                                                >
                                                    <h5>
                                                        <Button
                                                            htmlType="link"
                                                            onClick={() =>
                                                                history.push(
                                                                    "/create-challenge"
                                                                )
                                                            }
                                                        >
                                                            Create Challenge
                                                        </Button>
                                                    </h5>
                                                </div>
                                            </li>
                                            <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                history.push(
                                                                    "/create-club"
                                                                )
                                                            }>
                                                <figure>
                                                    <i className="icofont-users-social text-success mr-2"></i>
                                                    {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                                                />
                                                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                                            </svg>
                                                        </i> */}
                                                </figure>
                                                <div
                                                    className={`${styles["your-grp"]} your-grp`}
                                                >
                                                    <h5>
                                                        <Button
                                                            htmlType="link"
                                                            onClick={() =>
                                                                history.push(
                                                                    "/create-club"
                                                                )
                                                            }
                                                        >
                                                            Create Club
                                                        </Button>
                                                    </h5>
                                                </div>
                                            </li>
                                            {auth_user?.type ==
                                                    "hunter" &&
                                            (<li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                history.push(
                                                                    "/create-hunt"
                                                                )
                                                            }>
                                                <figure>
                                                    {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"></path>
                                                            </svg>
                                                        </i> */}
                                                    <i className="icofont-briefcase text-danger  mr-2"></i>
                                                </figure>
                                                <div
                                                    className={`${styles["your-grp"]} your-grp`}
                                                >
                                                    <h5>
                                                        <Button
                                                            htmlType="link"
                                                            onClick={() =>
                                                                history.push(
                                                                    "/create-hunt"
                                                                )
                                                            }
                                                        >
                                                            Create Hunt
                                                        </Button>
                                                    </h5>
                                                </div>
                                            </li>)}
                                        </>
                                    </Collapse>
                                </ul>
                            </div>
                            <div className="d-none d-lg-block col-md-3 mt-3">
                                <div className="d-none d-lg-block mb-3">
                                    <ul
                                        className={`${styles["ak-groups"]} ak-groups ${styles["ak-groups-top"]} ak-groups-top`}
                                    >
                                        <li
                                            className={`${styles["active-menu"]} mb-0 p-1`}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => history.push("/")}
                                        >
                                            <figure>
                                                {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-layers-fill" viewBox="0 0 16 16">
                                                        <path d="M7.765 1.559a.5.5 0 0 1 .47 0l7.5 4a.5.5 0 0 1 0 .882l-7.5 4a.5.5 0 0 1-.47 0l-7.5-4a.5.5 0 0 1 0-.882l7.5-4z" />
                                                        <path d="m2.125 8.567-1.86.992a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882l-1.86-.992-5.17 2.756a1.5 1.5 0 0 1-1.41 0l-5.17-2.756z" />
                                                    </svg>
                                                </i> */}
                                                <i className="icofont-layers text-info mr-2"></i>
                                            </figure>
                                            <div
                                                className={`${styles["your-grp"]} your-grp`}
                                            >
                                                <h5>On Reverie</h5>
                                            </div>
                                        </li>
                                        <li
                                            className="mb-0 p-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push("/challenge")
                                            }
                                        >
                                            <figure>
                                                {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-trophy-fill" viewBox="0 0 16 16">
                                                        <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                                                    </svg>
                                                </i> */}
                                                <i className="icofont-trophy text-warning mr-2"></i>
                                            </figure>
                                            <div
                                                className={`${styles["your-grp"]} your-grp`}
                                            >
                                                <h5>Challenges</h5>
                                            </div>
                                        </li>
                                        <li
                                            className="mb-0 p-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push("/hunting")
                                            }
                                        >
                                            <figure>
                                                {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                                                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z" />
                                                    </svg>
                                                </i> */}
                                                <i className="icofont-briefcase text-danger mr-2"></i>
                                            </figure>
                                            <div
                                                className={`${styles["your-grp"]} your-grp`}
                                            >
                                                <h5>Hunts</h5>
                                            </div>
                                        </li>
                                        <li
                                            className="mb-0 p-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                history.push("/clubs")
                                            }
                                        >
                                            <figure>
                                                {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                        <path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                                    </svg>
                                                </i> */}
                                                <i className="icofont-users-social text-success mr-2"></i>
                                            </figure>
                                            <div
                                                className={`${styles["your-grp"]} your-grp`}
                                            >
                                                <h5>Clubs</h5>
                                            </div>
                                        </li>
                                        <li
                                            className="create-btn mb-0 p-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                                taskCollapseRef.current?.collapse(
                                                    (e) => !e
                                                )
                                            }
                                        >
                                            <figure>
                                                {/* <i className="">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 24 24">
                                                        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                                                    </svg>
                                                </i> */}
                                                <i className="icofont-plus text-info mr-2"></i>
                                            </figure>
                                            <div
                                                className={`${styles["your-grp"]} your-grp`}
                                            >
                                                <h5>Create</h5>
                                            </div>
                                        </li>
                                        <Collapse ref={taskCollapseRef}>
                                            <>
                                                <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                            history.push(
                                                                "/create-post"
                                                            )
                                                        }>
                                                    <figure>
                                                        {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-save2-fill" viewBox="0 0 16 16">
                                                                <path d="M8.5 1.5A1.5 1.5 0 0 1 10 0h4a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h6c-.314.418-.5.937-.5 1.5v6h-2a.5.5 0 0 0-.354.854l2.5 2.5a.5.5 0 0 0 .708 0l2.5-2.5A.5.5 0 0 0 10.5 7.5h-2v-6z" />
                                                            </svg>
                                                        </i> */}
                                                        <i className="icofont-video text-danger mr-2"></i>
                                                    </figure>
                                                    <div
                                                        className={`${styles["your-grp"]} your-grp`}
                                                    >
                                                        <h5>
                                                            {/* <Button
                                                                htmlType="link"
                                                                onClick={() =>
                                                                    history.push(
                                                                        "/create-post"
                                                                    )
                                                                }
                                                            > */}
                                                                Upload Video
                                                            {/* </Button> */}
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                    history.push(
                                                                        "/create-challenge"
                                                                    )
                                                                }>
                                                    <figure>
                                                        <i className="icofont-trophy text-warning mr-2"></i>
                                                    </figure>
                                                    <div
                                                        className={`${styles["your-grp"]} your-grp`}
                                                    >
                                                        <h5>
                                                            <Button
                                                                htmlType="link"
                                                                onClick={() =>
                                                                    history.push(
                                                                        "/create-challenge"
                                                                    )
                                                                }
                                                            >
                                                                Create Challenge
                                                            </Button>
                                                        </h5>
                                                    </div>
                                                </li>
                                                <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                                    history.push(
                                                                        "/create-club"
                                                                    )
                                                                }>
                                                    <figure>
                                                        <i className="icofont-users-social text-success mr-2"></i>
                                                        {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                                                                />
                                                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                                            </svg>
                                                        </i> */}
                                                    </figure>
                                                    <div
                                                        className={`${styles["your-grp"]} your-grp`}
                                                    >
                                                        <h5>
                                                            {/* <Button
                                                                htmlType="link"
                                                                onClick={() =>
                                                                    history.push(
                                                                        "/create-club"
                                                                    )
                                                                }
                                                            > */}
                                                                Create Club
                                                            {/* </Button> */}
                                                        </h5>
                                                    </div>
                                                </li>
                                                {auth_user?.type ==
                                                    "hunter" && (
                                                    <li className="create-list ml-4 mb-0 p-1 cursor-pointer" onClick={() =>
                                                        history.push(
                                                            "/create-hunt"
                                                        )
                                                    }>
                                                        <figure>
                                                            {/* <i className="">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-badge-fill" viewBox="0 0 16 16">
                                                                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm4.5 0a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM8 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm5 2.755C12.146 12.825 10.623 12 8 12s-4.146.826-5 1.755V14a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-.245z"></path>
                                                            </svg>
                                                        </i> */}
                                                            <i className="icofont-briefcase text-danger  mr-2"></i>
                                                        </figure>
                                                        <div
                                                            className={`${styles["your-grp"]} your-grp`}
                                                        >
                                                            <h5>
                                                                {/* <Button
                                                                    htmlType="link"
                                                                    onClick={() =>
                                                                        history.push(
                                                                            "/create-hunt"
                                                                        )
                                                                    }
                                                                > */}
                                                                    Create Hunt
                                                                {/* </Button> */}
                                                            </h5>
                                                        </div>
                                                    </li>
                                                )}
                                            </>
                                        </Collapse>
                                    </ul>
                                </div>
                                <div className="d-none d-lg-block">
                                    {hunts.length > 0 && (
                                        <HuntsWidget
                                            hunts={hunts}
                                            heading="Hunting Highlights"
                                        />
                                    )}
                                </div>
                                <img src="/public/images/reverie-banner.png"></img>
                                {/* <a href="https://reverieglobal.com/challenge/23/accept">
                                    <img src="/public/images/challenge.jpg"></img>
                                </a> */}
                            </div>
                            <div className="col-lg-6 col-md-12">
                                {carouselPosts.length > 3 && (
                                    <PostVideoCarousel
                                        posts={carouselPosts.slice(0, 10)}
                                        autoPlay
                                        muted
                                    />
                                )}
                                {clubs.length > 0 && (
                                    <div className="main-wraper mt-4">
                                        <div className="chatroom-title">
                                            <i className="icofont-users-social text-success mr-2"></i>
                                            <span className="ml-2">
                                                Suggested Clubs
                                            </span>
                                        </div>
                                        <div className="suggested-clubs">
                                            <div className="">
                                                <SlickCarousel>
                                                    {renderClubs}
                                                </SlickCarousel>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {renderPosts}
                            </div>
                            <div className="d-none d-lg-block col-md-3 mt-3">
                                <div className={`${styles["mob-mar-t-20"]}`}>
                                    {challenges.length > 0 && (
                                        <ChallengesWidget
                                            challenges={challenges}
                                        />
                                    )}
                                </div>
                                <div className="mt-5">
                                    <nav className="responsive-tab style-4">
                                        <ul data-submenu-title="compounents" className="uk-list docs-list uk-sticky" uk-sticky="offset:50;media : @m">
                                            {renderInterests}
                                        </ul>
                                        <div className="uk-sticky-placeholder" style={{ height: '687px', margin: { top: 0, right: 0, bottom: 20, left: 0 } }}></div>
                                    </nav>
                                </div>
                                {users.length > 0 && (
                                    <SuggestedUsersWidget
                                        users={users}
                                        heading="Suggested Users"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="wraper-invite">
                    <div className="popup">
                        <span className="popup-closed">
                            <i className="icofont-close" />
                        </span>
                        <div className="popup-meta">
                            <div className="popup-head">
                                <h5>
                                    <i>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-mail"
                                        >
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                            <polyline points="22,6 12,13 2,6" />
                                        </svg>
                                    </i>{" "}
                                    Invite Colleagues
                                </h5>
                            </div>
                            <div className="invitation-meta">
                                <p>
                                    Enter an email address to invite a colleague
                                    or co-author to join you on socimo. They
                                    will receive an email and, in some cases, up
                                    to two reminders.
                                </p>
                                <form
                                    method="post"
                                    className={`${styles["c-form"]}`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Enter Email"
                                    />
                                    <button type="submit" className="main-btn">
                                        Invite
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* invite colleague popup */}
                <div className="popup-wraper">
                    <div className="popup">
                        <span className="popup-closed">
                            <i className="icofont-close" />
                        </span>
                        <div className="popup-meta">
                            <div className="popup-head">
                                <h5>
                                    <i>
                                        <svg
                                            className="feather feather-message-square"
                                            strokeLinejoin="round"
                                            strokeLinecap="round"
                                            strokeWidth={2}
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            height={24}
                                            width={24}
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                                        </svg>
                                    </i>{" "}
                                    Send Message
                                </h5>
                            </div>
                            <div className="send-message">
                                <form
                                    method="post"
                                    className={`${styles["c-form"]}`}
                                >
                                    <input
                                        type="text"
                                        placeholder="Enter Name.."
                                    />
                                    <input type="text" placeholder="Subject" />
                                    <textarea
                                        placeholder="Write Message"
                                        defaultValue={""}
                                    />
                                    <div className="uploadimage">
                                        <i className="icofont-file-jpg" />
                                        <label className="fileContainer">
                                            <input type="file" />
                                            Attach file
                                        </label>
                                    </div>
                                    <button type="submit" className="main-btn">
                                        Send
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="side-slide">
                    <span className="popup-closed">
                        <i className="icofont-close" />
                    </span>
                    <div className="slide-meta">
                        <div className="tab-content">
                            <div
                                className="tab-pane active fade show"
                                id="notifications"
                            >
                                <h4>
                                    <i className="icofont-bell-alt" />{" "}
                                    notifications
                                </h4>
                                <ul className="notificationz">
                                    <li>
                                        <figure>
                                            <img
                                                src="/public/images/resources/user5.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="mesg-info">
                                            <span>Alis wells</span>
                                            <a href="" title="">
                                                recommend your post
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <figure>
                                            <img
                                                src="/public/images/resources/user4.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="mesg-info">
                                            <span>Alis wells</span>
                                            <a href="" title="">
                                                share your post{" "}
                                                <strong>
                                                    a good time today!
                                                </strong>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <figure>
                                            <img
                                                src="/public/images/resources/user2.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="mesg-info">
                                            <span>Alis wells</span>
                                            <a href="" title="">
                                                recommend your post
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <figure>
                                            <img
                                                src="/public/images/resources/user1.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="mesg-info">
                                            <span>Alis wells</span>
                                            <a href="" title="">
                                                share your post{" "}
                                                <strong>
                                                    a good time today!
                                                </strong>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <figure>
                                            <img
                                                src="/public/images/resources/user3.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="mesg-info">
                                            <span>Alis wells</span>
                                            <a href="" title="">
                                                recommend your post
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <a
                                    href=""
                                    title=""
                                    className="main-btn"
                                    data-ripple
                                >
                                    view all
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-question-popup">
                    <div className="popup" style={{ width: "800px" }}>
                        <span className="popup-closed">
                            <i className="icofont-close" />
                        </span>
                        <div className="popup-meta">
                            <div className="popup-head">
                                <h5>
                                    <i>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="feather feather-plus"
                                        >
                                            <line
                                                x1={12}
                                                y1={5}
                                                x2={12}
                                                y2={19}
                                            />
                                            <line
                                                x1={5}
                                                y1={12}
                                                x2={19}
                                                y2={12}
                                            />
                                        </svg>
                                    </i>
                                    Upload Video
                                </h5>
                            </div>
                            <div className="post-new">
                                <div className="post-newmeta">
                                    <form
                                        method="post"
                                        className="dropzone mb-2"
                                        action="/upload-target"
                                    >
                                        <div className="fallback">
                                            <input
                                                name="file"
                                                type="file"
                                                multiple
                                            />
                                        </div>
                                    </form>
                                    <ul className="post-categoroes mb-0">
                                        <li>
                                            <i className="icofont-google-map" />{" "}
                                            Indoor Games
                                        </li>
                                        <li>
                                            <i className="icofont-file-gif" />{" "}
                                            Outdoor Games
                                        </li>
                                        <li>
                                            <i className="icofont-ui-tag" />{" "}
                                            Swiming
                                        </li>
                                        <li>
                                            <i className="icofont-users" />{" "}
                                            Cricket
                                        </li>
                                        <li>
                                            <i className="icofont-link" />{" "}
                                            Cycling
                                        </li>
                                        <li>
                                            <i className="icofont-video-cam" />{" "}
                                            Bike Riding
                                        </li>
                                        <li>
                                            <i className="icofont-sale-discount" />{" "}
                                            Activity
                                        </li>
                                        <li>
                                            <i className="icofont-read-book" />{" "}
                                            Public
                                        </li>
                                    </ul>
                                </div>
                                <form
                                    method="post"
                                    className={`${styles["c-form"]}`}
                                    style={{ width: "46%" }}
                                >
                                    <textarea
                                        id="emojionearea1"
                                        placeholder="What's On Your Mind?"
                                        defaultValue={""}
                                    />
                                    <div className="activity-post">
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox"
                                                defaultChecked
                                            />
                                            <label htmlFor="checkbox">
                                                <span>Activity Feed</span>
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox2"
                                                defaultChecked
                                            />
                                            <label htmlFor="checkbox2">
                                                <span>My Story</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="select-box mt-1 mb-3">
                                        <div
                                            className="select-box__current"
                                            tabIndex={1}
                                        >
                                            <div className="select-box__value">
                                                <input
                                                    className="select-box__input"
                                                    type="radio"
                                                    id={0}
                                                    defaultValue={1}
                                                    name="Ben"
                                                    defaultChecked="checked"
                                                />
                                                <p className="select-box__input-text">
                                                    <i className="icofont-globe-alt" />{" "}
                                                    Public
                                                </p>
                                            </div>
                                            <div className="select-box__value">
                                                <input
                                                    className="select-box__input"
                                                    type="radio"
                                                    id={1}
                                                    defaultValue={2}
                                                    name="Ben"
                                                    defaultChecked="checked"
                                                />
                                                <p className="select-box__input-text">
                                                    <i className="icofont-lock" />{" "}
                                                    Private
                                                </p>
                                            </div>
                                            <div className="select-box__value">
                                                <input
                                                    className="select-box__input"
                                                    type="radio"
                                                    id={2}
                                                    defaultValue={3}
                                                    name="Ben"
                                                    defaultChecked="checked"
                                                />
                                                <p className="select-box__input-text">
                                                    <i className="icofont-user" />{" "}
                                                    Specific Friend
                                                </p>
                                            </div>
                                            <div className="select-box__value">
                                                <input
                                                    className="select-box__input"
                                                    type="radio"
                                                    id={3}
                                                    defaultValue={4}
                                                    name="Ben"
                                                    defaultChecked="checked"
                                                />
                                                <p className="select-box__input-text">
                                                    <i className="icofont-star" />{" "}
                                                    Only Friends
                                                </p>
                                            </div>
                                        </div>
                                        <ul className="select-box__list">
                                            <li>
                                                <label
                                                    className="select-box__option"
                                                    htmlFor={0}
                                                >
                                                    <i className="icofont-globe-alt" />{" "}
                                                    Public
                                                </label>
                                            </li>
                                            <li>
                                                <label
                                                    className="select-box__option"
                                                    htmlFor={1}
                                                >
                                                    <i className="icofont-lock" />{" "}
                                                    Private
                                                </label>
                                            </li>
                                            <li>
                                                <label
                                                    className="select-box__option"
                                                    htmlFor={2}
                                                >
                                                    <i className="icofont-user" />{" "}
                                                    Specific Friend
                                                </label>
                                            </li>
                                            <li>
                                                <label
                                                    className="select-box__option"
                                                    htmlFor={3}
                                                >
                                                    <i className="icofont-star" />{" "}
                                                    Only Friends
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <button type="submit" className="main-btn">
                                        Publish
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="share-wraper">
                    <div className="share-options">
                        <span className="close-btn">
                            <i className="icofont-close-circled" />
                        </span>
                        <h5>
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-share"
                                >
                                    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
                                    <polyline points="16 6 12 2 8 6" />
                                    <line x1={12} y1={2} x2={12} y2={15} />
                                </svg>
                            </i>
                            Share To!
                        </h5>
                        <form method="post">
                            <textarea
                                placeholder="Write Something"
                                defaultValue={""}
                            />
                        </form>
                        <ul>
                            <li>
                                <a title="" href="#">
                                    Your Timeline
                                </a>
                            </li>
                            <li className="friends">
                                <a title="" href="#">
                                    To Friends
                                </a>
                            </li>
                        </ul>
                        <div style={{ display: "none" }} className="friends-to">
                            <div className="follow-men">
                                <figure>
                                    <img
                                        className="mCS_img_loaded"
                                        src="/public/images/resources/user1.jpg"
                                        alt=""
                                    />
                                </figure>
                                <div className="follow-meta">
                                    <h5>
                                        <a href="#" title="">
                                            Jack Carter
                                        </a>
                                    </h5>
                                    <span>family member</span>
                                </div>
                                <a href="#" title="">
                                    Share
                                </a>
                            </div>
                            <div className="follow-men">
                                <figure>
                                    <img
                                        className="mCS_img_loaded"
                                        src="/public/images/resources/user2.jpg"
                                        alt=""
                                    />
                                </figure>
                                <div className="follow-meta">
                                    <h5>
                                        <a href="#" title="">
                                            Xang Ching
                                        </a>
                                    </h5>
                                    <span>Close Friend</span>
                                </div>
                                <a href="#" title="">
                                    Share
                                </a>
                            </div>
                            <div className="follow-men">
                                <figure>
                                    <img
                                        className="mCS_img_loaded"
                                        src="/public/images/resources/user3.jpg"
                                        alt=""
                                    />
                                </figure>
                                <div className="follow-meta">
                                    <h5>
                                        <a href="#" title="">
                                            Emma Watson
                                        </a>
                                    </h5>
                                    <span>Matul Friend</span>
                                </div>
                                <a href="#" title="">
                                    Share
                                </a>
                            </div>
                        </div>
                        <button type="submit" className="main-btn">
                            Publish
                        </button>
                    </div>
                </div>
                {/* share post */}
                <div className="chat-box">
                    <div className="chat-head">
                        <h4>New Messages</h4>
                        <span className="clozed">
                            <i className="icofont-close-circled" />
                        </span>
                        <form method="post">
                            <input type="text" placeholder="To.." />
                        </form>
                    </div>
                    <div className="user-tabs">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a
                                    className="active"
                                    href="#link1"
                                    data-toggle="tab"
                                >
                                    Message Request
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="" href="#link2" data-toggle="tab">
                                    Chats
                                </a>
                                <em>3</em>
                            </li>
                        </ul>
                        {/* Tab panes */}
                        <div className="tab-content">
                            <div
                                className="tab-pane active fade show "
                                id="link1"
                            >
                                <div className="friend">
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user2.jpg"
                                                alt=""
                                            />
                                            <span className="status away" />
                                        </figure>
                                        <span>Amelia</span>
                                        <i className="permission">
                                            <button className="btn btn-outline-info btn-sm">
                                                Accept
                                            </button>
                                            <button className="btn btn-danger btn-sm">
                                                Reject
                                            </button>
                                        </i>
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user3.jpg"
                                                alt=""
                                            />
                                            <span className="status offline" />
                                        </figure>
                                        <span>George</span>
                                        <i className="permission">
                                            <button className="btn btn-outline-info btn-sm">
                                                Accept
                                            </button>
                                            <button className="btn btn-danger btn-sm">
                                                Reject
                                            </button>
                                        </i>
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user4.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Jacob</span>
                                        <i className="icofont-check-circled" />
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user5.jpg"
                                                alt=""
                                            />
                                            <span className="status away" />
                                        </figure>
                                        <span>Poppy</span>
                                        <i className="icofont-check-circled" />
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user6.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Sophia</span>
                                        <i className="">
                                            <img
                                                src="/public/images/resources/user6.jpg"
                                                alt=""
                                            />
                                        </i>
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user7.jpg"
                                                alt=""
                                            />
                                            <span className="status away" />
                                        </figure>
                                        <span>Leo king</span>
                                        <i className="">
                                            <img
                                                src="/public/images/resources/user7.jpg"
                                                alt=""
                                            />
                                        </i>
                                    </a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="link2">
                                <div className="friend">
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user1.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Samu Jane</span>
                                        <i className="">
                                            <img
                                                src="/public/images/resources/user1.jpg"
                                                alt=""
                                            />
                                        </i>
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user6.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Tina Mark</span>
                                    </a>
                                    <a href="#" title="">
                                        <figure>
                                            <img
                                                src="/public/images/resources/user5.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Ak William</span>
                                    </a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="link3">
                                <div className="friend">
                                    <a href="#" title="">
                                        <figure className="group-chat">
                                            <img
                                                src="/public/images/resources/user5.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="two"
                                                src="/public/images/resources/user3.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>Boys World</span>
                                        <i className="icofont-check-circled" />
                                    </a>
                                    <a href="#" title="">
                                        <figure className="group-chat">
                                            <img
                                                src="/public/images/resources/user2.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="two"
                                                src="/public/images/resources/user3.jpg"
                                                alt=""
                                            />
                                            <span className="status online" />
                                        </figure>
                                        <span>KK university Fellows</span>
                                        <i className="icofont-check-circled" />
                                    </a>
                                    <a href="#" title="">
                                        <figure className="group-chat">
                                            <img
                                                src="/public/images/resources/user3.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="two"
                                                src="/public/images/resources/user2.jpg"
                                                alt=""
                                            />
                                            <span className="status away" />
                                        </figure>
                                        <span>Education World</span>
                                        <i className="icofont-check-circled" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* chat box */}
                <div className="createroom-popup">
                    <div className="popup">
                        <span className="popup-closed">
                            <i className="icofont-close" />
                        </span>
                        <div className="popup-meta">
                            <div className="popup-head text-center">
                                <h5 className="only-icon">
                                    <i className="icofont-video-cam" />
                                </h5>
                            </div>
                            <div className="room-meta">
                                <h4>Create Your Club</h4>
                                <ul>
                                    <li>
                                        <i className="icofont-users-alt-4" />
                                        <div>
                                            <h6>Invite to All Friends</h6>
                                            <span>
                                                Allow All friends to see this
                                                room
                                            </span>
                                        </div>
                                        <div className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="checkbox5"
                                            />
                                            <label htmlFor="checkbox5" />
                                        </div>
                                    </li>
                                </ul>
                                <span>
                                    Your room isn't visible until you invite
                                    people after you've created it.
                                </span>
                                <a
                                    href="#"
                                    title=""
                                    className="main-btn full-width"
                                >
                                    Create Club
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* create new room */}
                <div className="modal fade" id="img-comt">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {/* Modal Header */}
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                >
                                    ×
                                </button>
                            </div>
                            {/* Modal body */}
                            <div className="modal-body">
                                <div className="row merged">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-md-2" />
                                            <div className="col-md-8">
                                                <div className="col-lg-9">
                                                    <div className="pop-image">
                                                        <div className="pop-item">
                                                            <div className="action-block">
                                                                <a className="action-button">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-tag"
                                                                    >
                                                                        <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" />
                                                                        <line
                                                                            x1={
                                                                                7
                                                                            }
                                                                            y1={
                                                                                7
                                                                            }
                                                                            x2="7.01"
                                                                            y2={
                                                                                7
                                                                            }
                                                                        />
                                                                    </svg>
                                                                </a>
                                                                <a className="action-button">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-map-pin"
                                                                    >
                                                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                                                                        <circle
                                                                            cx={
                                                                                12
                                                                            }
                                                                            cy={
                                                                                10
                                                                            }
                                                                            r={
                                                                                3
                                                                            }
                                                                        />
                                                                    </svg>
                                                                </a>
                                                                <a className="action-button">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-arrow-down"
                                                                    >
                                                                        <line
                                                                            x1={
                                                                                12
                                                                            }
                                                                            y1={
                                                                                5
                                                                            }
                                                                            x2={
                                                                                12
                                                                            }
                                                                            y2={
                                                                                19
                                                                            }
                                                                        />
                                                                        <polyline points="19 12 12 19 5 12" />
                                                                    </svg>
                                                                </a>
                                                                <a className="action-button">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={
                                                                            24
                                                                        }
                                                                        height={
                                                                            24
                                                                        }
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth={
                                                                            2
                                                                        }
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-more-vertical"
                                                                    >
                                                                        <circle
                                                                            cx={
                                                                                12
                                                                            }
                                                                            cy={
                                                                                12
                                                                            }
                                                                            r={
                                                                                1
                                                                            }
                                                                        />
                                                                        <circle
                                                                            cx={
                                                                                12
                                                                            }
                                                                            cy={
                                                                                5
                                                                            }
                                                                            r={
                                                                                1
                                                                            }
                                                                        />
                                                                        <circle
                                                                            cx={
                                                                                12
                                                                            }
                                                                            cy={
                                                                                19
                                                                            }
                                                                            r={
                                                                                1
                                                                            }
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                            <figure>
                                                                <img
                                                                    src="/public/images/resources/blog-detail.jpg"
                                                                    alt=""
                                                                />
                                                            </figure>
                                                            <div className="stat-tools">
                                                                <a
                                                                    title=""
                                                                    href="#"
                                                                    className="share-to"
                                                                >
                                                                    <i className="icofont-share-alt" />{" "}
                                                                    Share
                                                                </a>
                                                                <div className="emoji-state">
                                                                    <div className="popover_wrapper">
                                                                        <a
                                                                            className="popover_title"
                                                                            href="#"
                                                                            title=""
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                src="/public/images/resources/smiles/thumb.png"
                                                                            />
                                                                        </a>
                                                                        <div className="popover_content">
                                                                            <span>
                                                                                <img
                                                                                    alt=""
                                                                                    src="/public/images/resources/smiles/thumb.png"
                                                                                />{" "}
                                                                                Likes
                                                                            </span>
                                                                            <ul className="namelist">
                                                                                <li>
                                                                                    Jhon
                                                                                    Doe
                                                                                </li>
                                                                                <li>
                                                                                    Amara
                                                                                    Sin
                                                                                </li>
                                                                                <li>
                                                                                    Sarah
                                                                                    K.
                                                                                </li>
                                                                                <li>
                                                                                    <span>
                                                                                        20+
                                                                                        Fans
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="popover_wrapper">
                                                                        <a
                                                                            className="popover_title"
                                                                            href="#"
                                                                            title=""
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                src="/public/images/resources/smiles/heart.png"
                                                                            />
                                                                        </a>
                                                                        <div className="popover_content">
                                                                            <span>
                                                                                <img
                                                                                    alt=""
                                                                                    src="/public/images/resources/smiles/heart.png"
                                                                                />{" "}
                                                                                Love
                                                                            </span>
                                                                            <ul className="namelist">
                                                                                <li>
                                                                                    Amara
                                                                                    Sin
                                                                                </li>
                                                                                <li>
                                                                                    Jhon
                                                                                    Doe
                                                                                </li>
                                                                                <li>
                                                                                    <span>
                                                                                        10+
                                                                                        Fans
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="popover_wrapper">
                                                                        <a
                                                                            className="popover_title"
                                                                            href="#"
                                                                            title=""
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                src="/public/images/resources/smiles/smile.png"
                                                                            />
                                                                        </a>
                                                                        <div className="popover_content">
                                                                            <span>
                                                                                <img
                                                                                    alt=""
                                                                                    src="/public/images/resources/smiles/smile.png"
                                                                                />{" "}
                                                                                Happy
                                                                            </span>
                                                                            <ul className="namelist">
                                                                                <li>
                                                                                    Sarah
                                                                                    K.
                                                                                </li>
                                                                                <li>
                                                                                    Jhon
                                                                                    Doe
                                                                                </li>
                                                                                <li>
                                                                                    Amara
                                                                                    Sin
                                                                                </li>
                                                                                <li>
                                                                                    <span>
                                                                                        100+
                                                                                        Fans
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="popover_wrapper">
                                                                        <a
                                                                            className="popover_title"
                                                                            href="#"
                                                                            title=""
                                                                        >
                                                                            <img
                                                                                alt=""
                                                                                src="/public/images/resources/smiles/weep.png"
                                                                            />
                                                                        </a>
                                                                        <div className="popover_content">
                                                                            <span>
                                                                                <img
                                                                                    alt=""
                                                                                    src="/public/images/resources/smiles/weep.png"
                                                                                />{" "}
                                                                                Dislike
                                                                            </span>
                                                                            <ul className="namelist">
                                                                                <li>
                                                                                    Danial
                                                                                    Carbal
                                                                                </li>
                                                                                <li>
                                                                                    Amara
                                                                                    Sin
                                                                                </li>
                                                                                <li>
                                                                                    Sarah
                                                                                    K.
                                                                                </li>
                                                                                <li>
                                                                                    <span>
                                                                                        15+
                                                                                        Fans
                                                                                    </span>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <p>10+</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeLayout>
        </div>
    );
});

export default Home;
