import React from "react";

const InterestCarousel = (props) => {
    const interest = props.interest;
    return (
        <>
            <li className="uk-active">
                <a className={`${props.isActive ? "active" : ""}`} aria-expanded={`${props.isActive ? "true" : "false"}`} onClick={props.onClick}>
                    <p className="mb-0">{interest.title}</p>
                </a>
            </li>
        </>
    );
};

export default InterestCarousel;
